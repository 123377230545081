import { AppBar, Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { OriginContext } from '../../../../../states/origin/OriginContext';
import { AuthContext } from '../../../../../states/auth/AuthContext';
import CompaniesDropdown from '../CompaniesDropdown';
import Logout from '../../../assets/Logout';
import { CompanyContext } from '../../../../../states/company/CompanyContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LiveHelp from '@mui/icons-material/LiveHelp';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';

interface IProps {
  className?: string
}

const StyledHelpOutlineIcon = styled(HelpOutlineIcon)`
  color: ${({ theme }) => theme.styleGuide?.dynamic?.primary?.main};
`;

const HeaderAppBar = ({ className }: IProps) => {
  const { originInfos } = useContext(OriginContext);
  const { logout } = useContext(AuthContext);
  const [showMenu, setShowMenu] = useState(false);
  const { isGettingDefaultCompany } = useContext(CompanyContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    logout()
  }

  return (
    <div className={className}>
      <AppBar className="app_bar_container" position="fixed">
        <div className="grid_container">
          <div className="grid_container__companies-list">
            <img
              className="logo-svg"
              data-testid="header-logo-test"
              height={32}
              alt={`${originInfos?.storeName} Logo`}
              src={originInfos?.logoMain}
            />
            <div className="grid_container___companies-list-dropdown">
              {isGettingDefaultCompany ? (
                <Box className="loading-content">
                  <CircularProgress size={24} />
                </Box>
              ) : (
                <CompaniesDropdown
                  isOpen={showMenu}
                  onClose={() => setShowMenu(false)}
                  onOpen={() => setShowMenu(true)}
                />
              )}
            </div>
          </div>
          <div className="header_right_container">
            <IconButton color="inherit" onClick={handleClick}>
              <StyledHelpOutlineIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              style={{ zIndex: 8000, maxWidth: '300px' }}
            >
              <MenuItem onClick={() => { window.open('https://antecipafacil.com.br/perguntas-frequentes/', '_blank'); handleClose(); }}>
              <LiveHelp/>
                <Typography variant="body1" style={{paddingLeft: 8}}>Dúvidas frequentes</Typography>
              </MenuItem>
              <MenuItem disabled>
              <SmartDisplayIcon/>
                <Typography variant="body1" style={{paddingLeft: 8}}>Tutoriais em vídeo</Typography>
              </MenuItem>
              <Typography variant="body2" style={{ padding: '8px 16px', opacity: 0.6, fontSize: 12, textAlign: 'justify' }}>
                Caso sua dúvida persista, entre em contato com nosso suporte pelo chat presente no canto inferior direito.
              </Typography>
            </Menu>
            <IconButton color="inherit"  onClick={handleLogOut} data-testid="logout-button-header-test">
              <Logout width={24} height={24} />
            <span>Sair</span>
            </IconButton>
          </div>
        </div>
      </AppBar>
    </div>
  )
}

export default styled(HeaderAppBar)`
  header {
    padding-right: unset !important;
  }
  .app_bar_container {
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
    z-index: 5000;
    min-height: 56px;
  }

  .grid_container {
    align-items: center;
    box-shadow: unset;
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    height: 32px;

    @media (max-width: 550px) {
      .logo-svg {
        display: none;
      }

      padding: 12px 16px;
    }

    .grid_container__companies-list {
      display: flex;
      align-items: center;

      .grid_container___companies-list-dropdown {
        margin-left: 16px;
        z-index: 5000;
        display: flex;
        align-items: center;
        max-width: 340px;
        width: 100%;

        @media (max-width: 550px) {
          max-width: 300px;
          margin-left: unset;
        }

        .loading-content {
          max-height: 24px;
          margin-left: 24px;
          @media (max-width: 550px) {
            margin-left: unset;
          }
        }
      }
    }
  }

  .header_right_container {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    @media (max-width: 400px) {
      border-left: 2px solid ${({ theme }) => theme?.styleGuide?.dynamic?.primary?.main};
      padding-left: 24px;
    }

    ${Logout} {
      path {
        fill: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    span {
      color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      font-size: 16px;
      padding-left: 8px;
    }
  }
`
