import { useContext, useEffect, useState } from 'react'
import { Box, Tab, Tabs } from '@mui/material'
import styled from 'styled-components'
import { useAuth } from '../../../../../hooks/useAuth'
import { translateGroup } from '../../../../../utils/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import useTabs from '../Tabs'
import findTabFromGroup from '../Tabs/FindTabFromGroup'
import { getRedirectUrl } from '../../../../../utils/string'
import AlertModal, { AlertType } from '../../../../../components/modal/AlertModal'
import { CompanyContext } from '../../../../../states/company/CompanyContext'

interface INavBarProps {
  className?: string
}

const DesktopNavBar = ({ className }: INavBarProps) => {
  const navigate = useNavigate()
  const { defaultCompany } = useContext(CompanyContext)
  const { groups, loading } = useAuth()
  const group = translateGroup(groups?.[0])
  const [value, setValue] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const location = useLocation()
  const [customerTabs, buyerTabs] = useTabs()
  const redirectUrl = getRedirectUrl() as string

  const alertModalConfig = ['2', '5', '11'].includes(String(defaultCompany?.statusId))
    ? {
        type: 'success',
        texts: {
          title: 'Cadastro enviado para análise!',
          description: (
            <span>
              Aguarde enquanto <span style={{ fontWeight: 'bold' }}>analisamos sua documentação.</span> Entraremos em
              contato em breve para instruí-lo(a) sobre as próximas etapas.
            </span>
          ),
          confirmButton: 'Entendi'
        }
      }
    : {
        type: 'warning',
        texts: {
          title: 'Finalize seu cadastro!',
          description: (
            <span>
              <span style={{ fontWeight: 'bold' }}>Preencha os dados</span> da empresa e dos sócios e{' '}
              <span style={{ fontWeight: 'bold' }}>envie a documentação</span> necessária!
            </span>
          ),
          confirmButton: 'Finalizar'
        }
      }

  const items = group === 'Buyer' ? buyerTabs : customerTabs

  useEffect(() => {
    const currentTab =
      group === 'Customer'
        ? findTabFromGroup(customerTabs, location.pathname)
        : findTabFromGroup(buyerTabs, location.pathname)
    if (currentTab) {
      setValue(currentTab.index)
    }
  }, [location.pathname, group, customerTabs, buyerTabs])

  const handleTabClick = (path: string, redirectToV1?: boolean, blocked?: boolean) => {
    if (blocked) {
      setModalOpen(true)
      return
    }

    if (path.startsWith('http')) {
      window.open(path, '_blank')
      return
    }

    if (redirectToV1) {
      return window.location.replace(`${redirectUrl}${path}`)
    }

    navigate(path)
  }

  const renderTabs = () => {
    return loading ? (
      <></>
    ) : (
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        value={value}
        onChange={(_, newValue: number) => setValue(newValue)}
        aria-label="basic tabs"
      >
        {items.map((tab) => (
          <Tab
            data-testid="navbar-tab-test"
            key={tab.index}
            label={tab.label}
            {...getPosition(tab.index)}
            className={tab?.blockedTab ? 'blocked' : 'notBlocked'}
            onClick={() => handleTabClick(tab?.path as string, tab?.redirectToV1, tab.blockedTab)}
          />
        ))}
      </Tabs>
    )
  }

  const getPosition = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  })

  return (
    <div className={className} data-testid="desktop-nav-bar-test">
      <Box className="tabs-container">{renderTabs()}</Box>
      <AlertModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        submit={() => setModalOpen(false)}
        type={alertModalConfig.type as AlertType}
        texts={alertModalConfig.texts}
      ></AlertModal>
    </div>
  )
}

export default styled(DesktopNavBar)`
  @media (max-width: 600px) {
    display: none;
  }

  .tabs-container {
    top: 56px;
    background: ${({ theme }) => theme?.styleGuide?.fixed?.white?.light?.primary};
    box-shadow: ${({ theme: { elements } }) => elements?.primaryBoxShadow};
    position: fixed;
    z-index: 2000;
    left: 0;
    width: 100%;
    min-height: 48px;

    .blocked {
      color: ${({ theme }) => theme?.colors?.disabled};
    }
    .notBlocked {
      color: ${({ theme }) => theme?.colors?.quarternaryFont};
    }
    button {
      &:focus {
        font-weight: bold;
      }

      &:hover {
        color: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.primary?.main};
      }
    }

    .MuiTabs-indicator {
      background: ${({ theme: { styleGuide } }) => styleGuide?.dynamic?.secondary?.dark};
    }

    .Mui-selected {
      font-weight: bold !important;
    }
  }
`
